<template>
  <div>
    <div class="app-header1 header py-1 d-flex">
      <div class="container-fluid">
        <div class="d-flex ">
          <router-link class="header-brand" to="/">
            <img src="@/assets/images/logo.png" class="header-brand-img" />
          </router-link>
          <div class="d-flex align-items-center justify-content-between">
            <router-link
              class="network-nav-tab px-4"
              :to="`/${$t('routes.files')}`"
              >{{ $t('labels.files') }}</router-link
            >
            <router-link
              class="network-nav-tab px-4"
              :to="`/${$t('routes.documents')}`"
              >{{ $t('labels.documents') }}</router-link
            >
          </div>
          <div class="d-flex order-lg-2 ml-auto">
            <div class="dropdown d-none d-md-flex">
              <a class="nav-link icon full-screen-link">
                <i class="fe fe-maximize-2" id="fullscreen-button"></i>
              </a>
            </div>
            <div class="dropdown">
              <a href="#" class="nav-link pr-0 mt-3 " data-toggle="dropdown">
                {{ user.networkName }}
                <i class="fa fa-angle-down ml-2"></i>
              </a>
              <div
                class="dropdown-menu dropdown-menu-right dropdown-menu-arrow"
              >
                <a class="dropdown-item" @click="onLogout">
                  <i class="dropdown-icon icon icon-power"></i>
                  {{ $t('labels.logout') }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      selectedMenuItemName: null
    }
  },
  computed: { ...mapGetters(['user', 'isAuthenticated']) },
  methods: {
    onLogout() {
      this.$store.dispatch('logout')
    },
    hasActiveChild(childNames) {
      return childNames.includes(this.$route.name)
    },
    isMenuItemActive(menuItemName, hasChild = false) {
      let hasSelectedChildItem = false
      if (hasChild) {
        const filteredMenuItems = this.menuItems.filter(menuItem => {
          return (
            menuItemName === menuItem.name &&
            menuItem.childNames.includes(this.$route.name)
          )
        })
        hasSelectedChildItem = filteredMenuItems.length > 0
      }
      if (
        this.$route.name !== menuItemName &&
        !hasSelectedChildItem &&
        this.$route.meta.parentRoute === menuItemName
      ) {
        hasSelectedChildItem = true
      }
      return this.$route.name === menuItemName || hasSelectedChildItem
    }
  }
}
</script>

<style>
.network-nav-tab {
  font-size: 1rem;
}
.network-nav-tab.router-link-active {
  font-weight: bold;
  text-decoration: underline;
}
</style>
